import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import FusionAuthClient from "@fusionauth/typescript-client";

function Login({ setUserLoggedIn }) {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);

    const login = async () => {
        const client = new FusionAuthClient(
            "TfQuLtJt0DmLVbYX0GBFsD4PBz9v4_zlAy-vu9u1hcU-OdQTUbmqwMhO",
            "https://bik-p6pp-auth-dev.planetpop-qr.net"
        );
        setError(false);
        try {
            const response2 = await client.retrieveUserByLoginId(userName);
            console.log("retrieve user response: ", response2);
            const response = await client.login({
                loginId: userName,
                password,
                applicationId: "1347b63c-fb54-4260-bd1b-7096a57f66ba",
            });
            console.log("login response: ", response);
            if (response.wasSuccessful()) {
                console.log("calling set user logged in");
                setUserLoggedIn(true);
            } else {
                setError(true);
            }
        } catch (e) {
            console.log("error logging in user: ", e);
            setError(true);
        }
    };
    return (
        <div>
            <div
                style={{
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 200,
                }}
            >
                <TextField
                    label={"User Name"}
                    value={userName}
                    error={error}
                    margin={"normal"}
                    style={{ width: 400 }}
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => setUserName(event.target.value)}
                />
                <TextField
                    label={"Passwort"}
                    value={password}
                    type={"password"}
                    error={error}
                    margin={"normal"}
                    style={{ width: 400 }}
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => setPassword(event.target.value)}
                />
                {error ? <p>Incorrect username or password</p> : null}
                <Button variant={"contained"} onClick={login}>
                    {"Log In"}
                </Button>
            </div>
        </div>
    );
}

export default Login;
